import { useEffect, useState } from "react";
import { useData } from "./hooks";
import { AggregatedValue, AggregationType, DatabaseProperty, DatabaseQuery } from "./types";
import { getRowValue, isAggregationTypeCompatible, preformatValue, reduceAggregation } from "./utils";

const PROXY = 'https://cors-anywhere.remyhidra.dev/';

// const DEFAULT_DATA = {
//   id: '1',
//   name: 'Aggregation',
//   data: {
//     darkMode: true,
//     accessToken: 'secret_1NU6WUguYzwgrt6DobofEoq1ZpAXCpNNbUuC1uJI9Ph',
//     databaseId: '679240afc3da487dbbe7fe494b82c280',

//     propId: '%40*X7', // Screen time
//     // propId: 'vlrt', // Multi tag
//     // propId: 'KF%5DT', // Created time
//     // propId: '*%24DM', // Date
//     // propId: '.Y%2B%23', // Meditation (Checkbox)
//     // propId: 'H%7D%5CW', // Formula (Number)
//     // propId: 'Jq%5DR', // Rollup (Number)
//     aggregationType: AggregationType.AVERAGE,
//     label: '📱 Screen time',
//     fontFamily: 'default', // default, serif or mono

//     backgroundColor: '#143a4e',
//     textPrimaryColor: 'white',
//     textSecondaryColor: '#9b9b9b',

//     showUnit: true,
//     unit: 'min',
//     roundingPrecision: 3,
//     showHumanizedDate: true,
//     language: 'en_US',
//   },
// };

const DEFAULT_DATA = {
  id: '1',
  name: 'Aggregator',
  data: {
    darkMode: false,
    accessToken: '',
    databaseId: '',

    propId: '',
    aggregationType: AggregationType.COUNT_ALL,
    label: '',
    fontFamily: 'default', // default, serif or mono

    backgroundColor: '#143a4e',
    textPrimaryColor: 'white',
    textSecondaryColor: '#9b9b9b',

    showUnit: true,
    unit: 'min',
    roundingPrecision: 3,
    showHumanizedDate: true,
    language: 'en_US',
  },
};

function App() {
  const [, data, isPreview] = useData(DEFAULT_DATA.name, DEFAULT_DATA.data);
  const [rows, setRows] = useState<DatabaseProperty[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [unknownError, setUnknownError] = useState(false);
  const [value, setValue] = useState<AggregatedValue>();

  // Fetch the DB data to show in the graph
  useEffect(() => { (async () => {
    if (!data.databaseId || !data.accessToken || !data.propId || unknownError) { return; }

    try {
      setIsLoading(true);
      const queries: DatabaseQuery[] = [];

      // Fetch all the data
      // Handles paging for DB of more than 100 rows
      do {
        queries.push(await fetch(`${PROXY}https://api.notion.com/v1/databases/${data.databaseId}/query`, {
          method: 'POST',
          body: JSON.stringify({
            // filter: {
            //   or: [

            //   ]
            // },
            start_cursor: queries.length === 0 ? undefined : queries[queries.length-1].next_cursor,
            page_size: 100,
          }),
          headers: {
            'Authorization': `Bearer ${data.accessToken}`,
            'Notion-Version': '2022-02-22',
            'Content-Type': 'application/json',
          },
        }).then(r => r.json()) as DatabaseQuery);
      } while (queries[queries.length-1].next_cursor);

      // Merge the results in one query
      const query: DatabaseQuery = {...queries[0]};
      query.results = queries.map(q => q.results).reduce((prev, curr) => ([...prev, ...curr]), []);
      
      // Isolate only the interesting axis
      const rows = query.results
        .map(page => Object.values(page.properties).find(colData => colData.id === data.propId))
        .filter(page => !!page) as DatabaseProperty[];

      console.log(query, rows);

      setRows(rows);
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      setUnknownError(true);
      setIsLoading(false);
    }
  })()}, [data.accessToken, data.databaseId, data.propId, unknownError]);

  useEffect(() => { (async () => {
    if (unknownError || !rows || rows?.length === 0 || !isAggregationTypeCompatible(data.aggregationType, rows[0])) { 
      setValue('');
      return; 
    }

    const v = reduceAggregation(data.aggregationType, rows.map(r => getRowValue(r)));

    setValue(v);
    console.log(v)

    try {
    } catch (e) {
      console.error(e);
      setUnknownError(true);
      setIsLoading(false);
    }
  })()}, [rows, unknownError, data.aggregationType]);
  
  const hasConfigError = !isLoading && (!data.accessToken || !data.databaseId || !data.propId);
  const hasEmptyDB = !isLoading && (rows??[]).length === 0;
  const hasAggrTypeError = !isLoading && !hasConfigError && !hasEmptyDB && !isAggregationTypeCompatible(data.aggregationType, (rows??[])[0]);
  const hasSmallerFont = [AggregationType.DATE_RANGE, AggregationType.LATEST_DATE, AggregationType.EARLIEST_DATE].includes(data.aggregationType);

  const [integerPart, fractionalPart, unitSymbol] = preformatValue(value, data.aggregationType, data.unit, data.showUnit, data.roundingPrecision, data.showHumanizedDate, data.language);





  
  //// TODO: FIX showHumanizedDate not working on earliest date and latest date !!!






  return (
    <div className={`
      embed-root ${data.darkMode ? 'dark' : ''}
      font-${data.fontFamily ??  'default'}
    `}>

      <div className={`wrapper`}>
        {unknownError &&
          <div className="no-data">
            An unknown problem happened :(
            {!isPreview && <>
              <br/>Check your widget on widgeter.co
            </>}
          </div>
        }

        {!unknownError && !hasAggrTypeError && !hasConfigError && !hasEmptyDB && isLoading &&
          <div className="no-data">Loading...</div>
        }

        {!unknownError && hasConfigError && !hasAggrTypeError &&
          <div className="no-data">
            The widget is not configured correctly
            {!isPreview && <>
              <br/>Check your widget on widgeter.co
            </>}
          </div>
        }

        {!unknownError && !hasConfigError && hasAggrTypeError &&
          <div className="no-data">
            The aggregation type of your widget is not compatible with your property type.
            {!isPreview && <>
              <br/>Check your widget on widgeter.co
            </>}
          </div>
        }

        {!unknownError && !hasConfigError && !hasAggrTypeError && hasEmptyDB &&
          <div className="no-data">The database is empty</div>
        }

        {!unknownError && !hasConfigError && !hasAggrTypeError && !hasEmptyDB && !isLoading &&
          <main>
            <div className="frame" style={{backgroundColor: data.backgroundColor || 'inherit'}}>
              <div className="value-wrapper">
                {data.label &&
                  <div className="label" style={{color: data.textSecondaryColor}}>{data.label}</div>
                }

                <div className={`value ${hasSmallerFont ? 'small' : ''}`}>
                  <span className="integer-part" style={{color: data.textPrimaryColor}}>{integerPart}</span>
                  {fractionalPart &&
                    <span className="fractional-part" style={{color: data.textSecondaryColor}}>.{fractionalPart}</span>
                  }
                  <span className="unit-symbol" style={{color: data.textSecondaryColor}}>{unitSymbol}</span>
                </div>
              </div>
            </div>
          </main>
        }
      </div>

    </div>
  );
}

export default App;
