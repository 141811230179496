import { useEffect, useState } from 'react';

export function useData<T>(defaultName: string, defaultData: T): [string, T, boolean] {
  const [name, setName] = useState('');
  const [data, setData] = useState(defaultData);

  const [isPreview, setIsPreview] = useState(false);

  useEffect(() => {
    const update = () => {
      const {name: newName, data: newData} = JSON.parse(document.getElementById('EMBED_DATA')?.textContent ?? 'null') ?? {name: defaultName, data: defaultData};
      
      setName(newName ?? '');
      setData(newData ?? {});
    }

    if (isPreview) {
      const interval = setInterval(() => update(), 100);
      return () => clearInterval(interval);
    } else {
      update();
    }

  }, [isPreview, defaultData, defaultName]);

  useEffect(() => setIsPreview(!!document.querySelector('script.preview')), []);

  return [name, data, isPreview];
}