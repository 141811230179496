import { DateTime } from "luxon";

export type DatabaseQuery = {
  next_cursor: string|null,
  results: Array<{ 
    properties: {
      [key: string]: DatabaseProperty, 
    }, 
  }>,
};

export type DatabaseProperty = 
  NumberProperty | DateProperty | CheckboxProperty | FormulaProperty | RollupProperty 
  | RichTextProperty | TitleProperty | CreatedTimeProperty | SelectProperty | MultiSelectProperty
  | PeopleProperty | RelationProperty | CreatedByProperty | URLProperty | PhoneNumberProperty
  | EmailProperty | LastEditedByProperty | LastEditedTimeProperty | FilesProperty;

type TitleProperty =        { id: 'title', type: 'title', title: TextElement[] };
type NumberProperty =       { id: string, type: 'number', number: number };
type FormulaProperty =      { id: string, type: 'formula', formula: DateProperty | NumberProperty | CheckboxProperty| RichTextProperty };
type RollupProperty =       { id: string, type: 'rollup', rollup: DateProperty | NumberProperty | CheckboxProperty | RichTextProperty };
type RichTextProperty =     { id: string, type: 'rich_text', rich_text: (TextElement | EquationElement)[] };
type CheckboxProperty =     { id: string, type: 'checkbox', checkbox: boolean };
type RelationProperty =     { id: string, type: 'relation', relation: { id: string }[] };
type DateProperty =         { id: string, type: 'date', date: { start: string, end: null, time_zone: null } };
type PhoneNumberProperty =  { id: string, type: 'phone_number', phone_number: string };
type URLProperty =          { id: string, type: 'url', url: string };
type EmailProperty =        { id: string, type: 'email', email: string };
type SelectProperty =       { id: string, type: 'select', select: { id: string, name: string, color: string } };
type MultiSelectProperty =  { id: string, type: 'multi_select', multi_select: { id: string, name: string, color: string }[] };
type PeopleProperty =       { id: string, type: 'people', people: Person[] };
type CreatedTimeProperty =  { id: string, type: 'created_time', created_time: string };
type CreatedByProperty =    { id: string, type: 'created_by', created_by: Person };
type LastEditedTimeProperty={ id: string, type: 'last_edited_time', last_edited_time: string };
type LastEditedByProperty = { id: string, type: 'last_edited_by', last_edited_by: Person };
type FilesProperty =        { id: string, type: 'files', files: { name: string, type: 'file', file: { url: string, expiry_time: string } }[] };

type TextElement = {
  type: 'text',
  text: {
    content: string,
    link: { url: string } | null,
  },
  annotations: TextAnnotations,
  plain_text: string,
  href: string|null,
}

type EquationElement = {
  type: 'equation',
  equation: { expression: string },
  annotations: TextAnnotations,
  plain_text: string,
  href: string|null,
}

type TextAnnotations = {
  bold: boolean,
  italic: boolean,
  strikethrough: boolean,
  underline: boolean,
  code: boolean,
  color: string,
}

type Person = {
  object: 'user',
  id: string,
  name: string,
  avatar_url: string,
  type: 'person',
  person: {email: string} | any,
}


export enum AxisType {
  NUMBER, DATE, CATEGORY,
}

export enum AggregationType {
  // For all axis types
  COUNT_ALL="countAll",
  COUNT_VALUES="countValues",
  COUNT_UNIQUE_VALUES="countUniqueValues",
  COUNT_EMPTY="countEmpty",
  COUNT_NOT_EMPTY="countNotEmpty",
  PERCENT_EMPTY="percentEmpty",
  PERCENT_NOT_EMPTY="percentNotEmpty",

  // For dates
  EARLIEST_DATE="earliestDate",
  LATEST_DATE="latestDate",
  DATE_RANGE="dateRange",

  // For checkboxes
  CHECKED="checked",
  UNCHECKED="unchecked",
  PERCENT_CHECKED="percentChecked",
  PERCENT_UNCHECKED="percentUnchecked",

  // For numbers
  SUM="sum",
  AVERAGE="average",
  MEDIAN="median",
  MIN="min",
  MAX="max",
  RANGE="range",
}

export type RowValue = AggregatedValue|string[];
export type AggregatedValue = number|string|DateTime|undefined|boolean;