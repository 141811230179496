import { DateTime, Interval } from "luxon";
import { AggregatedValue, AggregationType, DatabaseProperty, RowValue } from "./types";
import humanizeDuration from 'humanize-duration';

export function isAggregationTypeCompatible(aggr: AggregationType, row: DatabaseProperty): boolean {
  if (!row || !aggr) { return false; }

  switch (aggr) {
    // Default aggregations
    case AggregationType.COUNT_ALL:
    case AggregationType.COUNT_VALUES:
    case AggregationType.COUNT_UNIQUE_VALUES:
    case AggregationType.COUNT_EMPTY:
    case AggregationType.COUNT_NOT_EMPTY:
    case AggregationType.PERCENT_EMPTY:
    case AggregationType.PERCENT_NOT_EMPTY:
      return true;
    
    // Date types
    case AggregationType.EARLIEST_DATE:
    case AggregationType.LATEST_DATE:
    case AggregationType.DATE_RANGE:
      return row.type === 'date' || row.type === 'created_time' || row.type === 'last_edited_time'
          || (row.type === 'formula' && row.formula.type === 'date') 
          || (row.type === 'rollup' && row.rollup.type === 'date');
    
    // Checkbox types
    case AggregationType.CHECKED:
    case AggregationType.UNCHECKED:
    case AggregationType.PERCENT_CHECKED:
    case AggregationType.PERCENT_UNCHECKED:
      return row.type === 'checkbox'
          || (row.type === 'formula' && row.formula.type === 'checkbox')
          || (row.type === 'rollup' && row.rollup.type === 'checkbox');
    
    // Numbers
    case AggregationType.SUM:
    case AggregationType.AVERAGE:
    case AggregationType.MEDIAN:
    case AggregationType.MIN:
    case AggregationType.MAX:
    case AggregationType.RANGE:
      return row.type === 'number'
          || (row.type === 'formula' && row.formula.type === 'number')
          || (row.type === 'rollup' && row.rollup.type === 'number');
  }
}

export function reduceAggregation(aggr: AggregationType, rows: RowValue[]): AggregatedValue {
  if (!aggr) { throw Error('Aggregation type undefined !'); }
  if (!rows) { return undefined; }

  switch (aggr) {
    // Default aggregations
    case AggregationType.COUNT_ALL:
      return rows.length;
    case AggregationType.COUNT_VALUES:
      return rows.reduce<number>((acc, c) => acc + (Array.isArray(c) ? c.length : (c ? 1 : 0)), 0);
    case AggregationType.COUNT_UNIQUE_VALUES:
      return new Set(rows.reduce<RowValue[]>((acc, c) => Array.isArray(c) ? [...acc, ...c] : [...acc, c], []).filter(e => !!e)).size;
    case AggregationType.COUNT_EMPTY:
      return rows.filter(r => Array.isArray(r) ? r.length === 0 : !r).length;
    case AggregationType.COUNT_NOT_EMPTY:
      return rows.filter(r => Array.isArray(r) ? r.length !== 0 : !!r).length;
    case AggregationType.PERCENT_EMPTY:
      if (rows.length === 0) { return 0; }
      return rows.filter(r => Array.isArray(r) ? r.length === 0 : !r).length / rows.length * 100;
    case AggregationType.PERCENT_NOT_EMPTY:
      if (rows.length === 0) { return 0; }
      return rows.filter(r => Array.isArray(r) ? r.length !== 0 : !!r).length / rows.length * 100;
    
    // Date types
    case AggregationType.EARLIEST_DATE:
      return (rows as DateTime[]).filter(r => !!r && typeof r.toMillis === 'function').reduce((min, r) => min.toMillis() < r.toMillis() ? min : r);
    case AggregationType.LATEST_DATE:
      return (rows as DateTime[]).filter(r => !!r && typeof r.toMillis === 'function').reduce((max, r) => max.toMillis() > r.toMillis() ? max : r);
    case AggregationType.DATE_RANGE:
      const start = (rows as DateTime[]).filter(r => !!r && typeof r.toMillis === 'function').reduce((min, r) => min.toMillis() < r.toMillis() ? min : r);
      const end = (rows as DateTime[]).filter(r => !!r && typeof r.toMillis === 'function').reduce((max, r) => max.toMillis() > r.toMillis() ? max : r);
      return Interval.fromDateTimes(start, end).toDuration().toMillis();
    
    // Checkbox types
    case AggregationType.CHECKED:
      return (rows as boolean[]).reduce((acc, b) => acc + (b ? 1 : 0), 0);
    case AggregationType.UNCHECKED:
      return (rows as boolean[]).reduce((acc, b) => acc + (b ? 0 : 1), 0);
    case AggregationType.PERCENT_CHECKED:
      if (rows.length === 0) { return 0; }
      return (rows as boolean[]).reduce((acc, b) => acc + (b ? 1 : 0), 0) / rows.length * 100;
    case AggregationType.PERCENT_UNCHECKED:
      if (rows.length === 0) { return 0; }
      return (rows as boolean[]).reduce((acc, b) => acc + (b ? 0 : 1), 0) / rows.length * 100;
    
    // Numbers
    case AggregationType.SUM:
      return (rows as number[]).reduce((acc, c) => acc + c, 0);
    case AggregationType.AVERAGE:
      if (rows.length === 0) { return 0; }
      return (rows as number[]).reduce((acc, c) => acc + c, 0) / rows.length;
    case AggregationType.MEDIAN:
      const i = (rows.length%2===0) ? (rows.length + 1)/2 : rows.length / 2;
      return (rows as number[])[Math.floor(i)];
    case AggregationType.MIN:
      return Math.min(...(rows as number[]).filter(r => r !== undefined && r !== null));
    case AggregationType.MAX:
      return Math.max(...(rows as number[]).filter(r => r !== undefined && r !== null));
    case AggregationType.RANGE:
      const r = (rows as number[]).filter(r => r !== undefined && r !== null);
      return Math.max(...r) - Math.min(...r);
    
    default:
      throw Error('Error: Unknown aggregation type !');
  }
}

export function getRowValue(row: DatabaseProperty): RowValue {
  if (!row) { return undefined; }

  switch (row.type) {
    case 'number':
      return row.number;
    case 'date':
      return row.date ? DateTime.fromISO(row.date.start) : undefined;
    case 'rich_text':
      return row.rich_text ? row.rich_text.reduce((s, cur) => s + cur.plain_text, '') : undefined;
    case 'title':
      return row.title ? row.title.reduce((s, cur) => s + cur.plain_text, '') : undefined;
    case 'checkbox':
      return row.checkbox;
    case 'formula':
      return getRowValue(row.formula);
    case 'rollup':
      return getRowValue(row.rollup);
    case 'created_time':
      return row.created_time ? DateTime.fromISO(row.created_time) : undefined;
    case 'created_by':
      return row.created_by.name;
    case 'people':
      return row.people.map(p => p.name);
    case 'select':
      return row.select?.name;
    case 'multi_select':
      return row.multi_select.map(p => p.name);
    case 'relation':
      return row.relation.map(p => p.id);
    case 'email':
      return row.email;
    case 'phone_number':
      return row.phone_number;
    case 'last_edited_by':
      return row.last_edited_by.name;
    case 'last_edited_time':
      return row.last_edited_time ? DateTime.fromISO(row.last_edited_time) : undefined;
    case 'url':
      return row.url;
    case 'files':
      return row.files.map(p => p.name);

    default:
      console.error(`Error: Type "${(row as any)?.type}" not supported`);
      return undefined;
  }
}

export function preformatValue(value: AggregatedValue, aggr: AggregationType, unit: string = '', 
                              showUnit: boolean, roundingFractPart: number, showHumanizedDate: boolean, language: string): [string, string, string] {

  if (!aggr) { throw Error('Aggregation type undefined !'); }
  if (roundingFractPart < 0) { 
    roundingFractPart = 0;
    console.error('Wrong rounding fractional part value !'); 
  }

  type t = (a?:any) => [string, string, string];

  const formatInteger: t = () => ([`${Math.floor(value as number || 0)}`, '', showUnit ? (unit || '') : '']);
  const formatNumber: t = (forceFract: boolean = false) => {
    const n = Math.floor(value as number || 0);
    const f = (value as number || 0) - n;
    const f_rounded = Math.floor(f * Math.pow(10, roundingFractPart));
    const f_formatted = f_rounded === 0 ? (forceFract ? '0' : '') : `${f_rounded}`;
    const f_padded = f_formatted ? f_formatted.padStart(roundingFractPart, '0') : f_formatted;
    return [`${n}`, roundingFractPart === 0 ? '' : f_padded, showUnit ? unit : ''];
  };
  const formatPercent: t = () => {
    const [v, f] = formatNumber();
    return [v, f, showUnit ? '%' : ''];
  };
  const formatDate: t = () => {
    if (!value || !(value as DateTime).setLocale) {
      return ['', '', ''];
    } else if (showHumanizedDate) {
      const ms = Interval.fromDateTimes(DateTime.now(), value as DateTime).toDuration().valueOf(); 
      return [humanizeDuration(ms, { language, fallbacks: ['en'] }), '', ''];
    } else {
      const s = (value as DateTime).setLocale(language || 'en').toLocaleString(DateTime.DATE_MED);
      return [s, '', ''];
    }
  };
  const formatDateRange: t = () => ([humanizeDuration(value as number || 0, { language, fallbacks: ['en'] }), '', '']);

  switch (aggr) {
    // Default aggregations
    case AggregationType.COUNT_ALL:
    case AggregationType.COUNT_VALUES:
    case AggregationType.COUNT_UNIQUE_VALUES:
    case AggregationType.COUNT_EMPTY:
    case AggregationType.COUNT_NOT_EMPTY:
      return formatInteger();

    case AggregationType.PERCENT_EMPTY:
    case AggregationType.PERCENT_NOT_EMPTY:
      return formatPercent();

    // Date types
    case AggregationType.EARLIEST_DATE:
    case AggregationType.LATEST_DATE:
      return formatDate();
    case AggregationType.DATE_RANGE:
      return formatDateRange();
    
    // Checkbox types
    case AggregationType.CHECKED:
    case AggregationType.UNCHECKED:
      return formatInteger();
    case AggregationType.PERCENT_CHECKED:
    case AggregationType.PERCENT_UNCHECKED:
      return formatPercent();
    
    // Numbers
    case AggregationType.SUM:
    case AggregationType.AVERAGE:
    case AggregationType.MEDIAN:
    case AggregationType.MIN:
    case AggregationType.MAX:
    case AggregationType.RANGE:
      return formatNumber(true);
  }
}
